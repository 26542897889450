import { DTOExporterPropertyDefinitionBoolean } from "@supernova-studio/client"

import {
  ExporterPropertyBase,
  ExporterPropertyType,
} from "./SDKExporterPropertyBase"

export class ExporterPropertyDefinitionBoolean extends ExporterPropertyBase {
  // --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
  // MARK: - Public properties

  type: ExporterPropertyType = "Boolean"

  defaultValue: boolean

  // --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
  // MARK: - Constructor

  constructor(model: DTOExporterPropertyDefinitionBoolean) {
    super(model)

    this.defaultValue = model.default
  }
}
