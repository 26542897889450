import React from "react"

import { cva, VariantProps } from "class-variance-authority"

const textVariants = cva(["text-critical whitespace-pre-line"], {
  variants: {
    size: {
      small: "text-body-small font-semibold",
      medium: "text-body-small font-semibold",
      large: "text-body font-semibold",
    },
  },
})

export type DMErrorMessageProps = Pick<
  React.HTMLAttributes<HTMLParagraphElement>,
  "id"
> &
  Required<VariantProps<typeof textVariants>> & {
    children: React.ReactNode
  }

const DMErrorMessage = React.forwardRef<
  HTMLParagraphElement,
  DMErrorMessageProps
>(({ children, size = "medium", ...restProps }, ref) => {
  return (
    <p ref={ref} className={textVariants({ size })} {...restProps}>
      {children}
    </p>
  )
})

DMErrorMessage.displayName = "DMErrorMessage"

export { DMErrorMessage }
