// SDKExportDestinations.ts
// Supernova SDK
//
// Created by Emir Bostanci.
// Copyright © 2023 Supernova. All rights reserved.
//
// MARK: - S3 Export Build Result
// TODO:fix-sdk-eslint
// eslint-disable-next-line max-classes-per-file
export type S3ExportBuildResultModel = {
  bucket: string
  files: Array<string>
  url: string
}

/** The type definition for the data model of an S3 export build result. */
export class S3ExportBuildResult {
  /** The S3 bucket associated with the export build result */
  bucket: string

  /** An array of URLs associated with the export build result */
  files: Array<string>

  /** A URL associated with the export build result */
  url: string

  constructor(data: S3ExportBuildResultModel) {
    this.bucket = data.bucket
    this.files = data.files
    this.url = data.url
  }
}

// MARK: - Export build pull request result
export type ExportBuildPullRequestResultModel = {
  pullRequestUrl: string
}

/** The type definition for the data model of an export build pull request result. */
export class ExportBuildPullRequestResult {
  /** The URL of the pull request associated with the export build result. */
  pullRequestUrl: string

  constructor(data: ExportBuildPullRequestResultModel) {
    this.pullRequestUrl = data.pullRequestUrl
  }
}

export type ExportBuildDocumentationResultModel = {
  url: string
}

/** The type definition for documentation build's result. */
export class ExportBuildDocumentationResult {
  /** The URL of documentation's website associated with the export build result. */
  url: string

  constructor(data: ExportBuildDocumentationResultModel) {
    this.url = data.url
  }
}

/**
 * The enum representing possible types of export build result logs.
 * @enum {string}
 */
export enum ExportBuildResultLogType {
  success = "success",
  info = "info",
  warning = "warning",
  user = "user",
  error = "error",
}

export type ExportBuildResultLogModel = {
  time: string
  type: ExportBuildResultLogType
  message: string
}

export class ExportBuildResultLog {
  /** The time when the log entry was created */
  time: Date

  /** The type of the log entry */
  type: ExportBuildResultLogType

  /** The message of the log entry */
  message: string

  constructor(data: ExportBuildResultLogModel) {
    this.time = new Date(data.time)
    this.type = data.type
    this.message = data.message
  }
}

// MARK: - Export build result
export type ExportBuildResultModel = {
  logs: Array<ExportBuildResultLog>
  s3?: S3ExportBuildResult
  azure?: ExportBuildPullRequestResult
  bitbucket?: ExportBuildPullRequestResult
  github?: ExportBuildPullRequestResult
  gitlab?: ExportBuildPullRequestResult
  documentation?: ExportBuildDocumentationResult
  error?: string
}

export class ExportBuildResult {
  /** An array of log entries associated with the export build result */
  logs: Array<ExportBuildResultLog>

  /** The S3 export build result, or null if not available */
  s3?: S3ExportBuildResult

  /** The Azure export build pull request result, or null if not available */
  azure?: ExportBuildPullRequestResult

  /** The Bitbucket export build pull request result, or null if not available */
  bitbucket?: ExportBuildPullRequestResult

  /** The GitHub export build pull request result, or null if not available */
  github?: ExportBuildPullRequestResult

  /** The GitLab export build pull request result, or null if not available */
  gitlab?: ExportBuildPullRequestResult

  /** The documentation build result, or null if not available */
  documentation?: ExportBuildDocumentationResult

  /** The error message, or null if not available */
  error?: string

  constructor(data: ExportBuildResultModel) {
    this.logs = data.logs
    this.s3 = data.s3
    this.azure = data.azure
    this.bitbucket = data.bitbucket
    this.github = data.github
    this.gitlab = data.gitlab
    this.documentation = data.documentation
    this.error = data.error
  }
}
