import React, { ReactNode } from "react"

import { DMIcon } from "@supernovaio/dm/src/components/DMIcon"
import { cn } from "@supernovaio/dm/src/utils/cn"

import { IconType } from "@supernovaio/icons/types"

type BaseProps = {
  size?: "small" | "medium"
  isDisabled?: boolean
}

export type DMTwoDashedCircleIconProps = BaseProps & {
  icon?: IconType
  iconColor?: "neutral" | "neutral-faded" | "disabled"
}

type ChildrenProps = BaseProps & {
  children: ReactNode
}

export type DMTwoDashedCircleProps = DMTwoDashedCircleIconProps | ChildrenProps

export function DMTwoDashedCircles(props: DMTwoDashedCircleProps) {
  const { size = "small", isDisabled } = props

  let iconSlot: ReactNode
  if ("icon" in props && props.icon && "iconColor" in props) {
    const { icon, iconColor = "neutral" } = props
    iconSlot = <DMIcon svg={icon} size="medium" color={iconColor} />
  } else if ("children" in props) {
    iconSlot = props.children
  }

  return (
    <div
      className={cn("grid place-content-center", {
        "size-[80px]": size === "small",
        "size-[88px]": size === "medium",
      })}
    >
      <div
        className={cn(
          "grid place-content-center rounded-full group-data-[state=checked]:bg-elevation-base",
          {
            "size-[72px]": size === "small",
            "size-[86px]": size === "medium",
            "group-hover:bg-elevation-base": !isDisabled,
          }
        )}
      >
        <div
          className={cn(
            "grid place-content-center rounded-full border border-dashed border-neutral",
            {
              "border-disabled": isDisabled,
              "size-[74px]": size === "small",
              "size-[88px]": size === "medium",
            }
          )}
        >
          <div
            className={cn("border border-dashed border-neutral rounded-full", {
              "border-disabled": isDisabled,
              "size-32": size === "small",
              "size-[38px]": size === "medium",
            })}
          >
            <div className="h-full grid place-content-center overflow-hidden rounded-full">
              {iconSlot}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
