import {
  DTOExporterPropertyDefinitionObject,
  DTOExporterPropertyDefinitionString,
} from "@supernova-studio/client"

import {
  ExporterPropertyBase,
  ExporterPropertyType,
} from "./SDKExporterPropertyBase"

// While this could be used directly as the array without being wrapped in its own type,
// this will allow us to add the `type` support later if needed
type AllowedKeys = { options: Array<string> }

export class ExporterPropertyDefinitionObject extends ExporterPropertyBase {
  // --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
  // MARK: - Public properties

  type: ExporterPropertyType = "Object"

  allowedKeys: AllowedKeys

  defaultValues: Record<string, string>

  // --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
  // MARK: - Constructor

  constructor(model: DTOExporterPropertyDefinitionObject) {
    super(model)

    this.allowedKeys = model.allowedKeys ?? { options: [] }
    this.defaultValues = model.default
  }
}
