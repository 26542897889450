import React, { createContext, useContext } from "react"

import Link from "next/link"

import * as Ariakit from "@ariakit/react"

import { cn } from "../../utils/cn"

// Context
// --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---

type UnderlineStylingContextProps = {
  variant: "underline"
  color: "neutral" | "primary"
  size: "small" | "medium"
}

type PillsStylingContextProps = {
  variant: "pills"
  size: "small" | "medium"
}

type StylingContextProps =
  | UnderlineStylingContextProps
  | PillsStylingContextProps

const StylingContext = createContext<StylingContextProps | undefined>(undefined)

// Tab
// --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---

type DMTabsProps = Ariakit.TabProviderProps

export function DMTabs(props: DMTabsProps) {
  return <Ariakit.TabProvider {...props} />
}

// Tab list
// --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---

type DMTabListProps = StylingContextProps & {
  className?: string
  children: React.ReactNode
}

export function DMTabList({ className, children, ...props }: DMTabListProps) {
  return (
    <StylingContext.Provider value={props}>
      <Ariakit.TabList
        className={cn(
          className,
          "flex flex-row",
          props.variant === "pills" && "p-2 gap-4 rounded-medium bg-blur-neutral-faded",
          props.variant === "underline" && [
            props.size === "small" && "gap-4",
            props.size === "medium" && "gap-16",
          ]
        )}
      >
        {children}
      </Ariakit.TabList>
    </StylingContext.Provider>
  )
}

// Tab item
// --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---

type DMTabItemProps = {
  /**
   * This is useful when controlling the selection from the outside,
   * otherwise it can be left empty, and it will get autogenerated.
   *
   * When using `href`, it will be used as a fallback id
   */
  id?: string
  children: React.ReactNode

  /**
   * When using href, the component is rendered using `Link` instead of `button`
   */
  href?: string
}

export function DMTabItem(props: DMTabItemProps) {
  const context = useContext(StylingContext)
  if (!context) throw new Error("DMTabItem must be used within DMTabList")

  switch (context.variant) {
    case "underline":
      return (
        <UnderlineTabItem {...props} context={context}>
          {props.children}
        </UnderlineTabItem>
      )

    case "pills":
      return (
        <PillTabItem {...props} context={context}>
          {props.children}
        </PillTabItem>
      )
  }
}

type UnderlineTabItemProps = DMTabItemProps & {
  context: UnderlineStylingContextProps
}

function UnderlineTabItem({ context, ...props }: UnderlineTabItemProps) {
  const customId = props.id ?? props.href?.toString()

  return (
    <Ariakit.Tab
      // The component will automatically generate an id, but only if the `id` prop isn't provided,
      // so we cannot just pass in null or undefined, but we have to omit the prop entirely
      {...(customId ? { id: customId } : {})}
      className={cn(
        "group/tab-button",
        "flex font-semibold",
        context.size === "small" && "pt-[6px]",
        context.size === "medium" && "pt-8"
      )}
      render={(localProps) => {
        if (props.href) {
          return (
            <Link href={props.href} {...localProps}>
              <UnderlineItemContent {...props} context={context}>
                {props.children}
              </UnderlineItemContent>
            </Link>
          )
        }
        return (
          <button type="button" {...localProps}>
            <UnderlineItemContent {...props} context={context}>
              {props.children}
            </UnderlineItemContent>
          </button>
        )
      }}
    />
  )
}

// Internal content for the underline item, since it is used in both link and button versions
function UnderlineItemContent({ context, ...props }: UnderlineTabItemProps) {
  return (
    <div
      className={cn(
        "flex flex-col",
        // The gap needs to be 2px smaller than the top padding, to account for the underline
        context.size === "small" && "gap-4",
        context.size === "medium" && "gap-[6px]"
      )}
    >
      {/* The text has its separate background which doesn't extend
              all the way to the top/bottom of the full button */}
      <div
        className={cn(
          "flex flex-col px-8 py-[6px] rounded text-neutral-faded",
          "group-hover/tab-button:bg-neutral",
          "group-aria-selected/tab-button:text-neutral"
        )}
      >
        {props.children}
      </div>

      {/* Bottom selection line */}
      <div className={cn(context.size === "small" && "px-8")}>
        <div
          className={cn(
            "w-full h-[2px]",
            "invisible group-aria-selected/tab-button:visible"
          )}
          style={{
            backgroundColor:
              context.color === "primary"
                ? "var(--rs-color-foreground-primary)"
                : "var(--rs-color-foreground-neutral)",
          }}
        />
      </div>
    </div>
  )
}

type PillsTabItemProps = DMTabItemProps & {
  context: PillsStylingContextProps
}

function PillTabItem({ context, ...props }: PillsTabItemProps) {
  const customId = props.id ?? props.href?.toString()

  return (
    <Ariakit.Tab
      // The component will automatically generate an id, but only if the `id` prop isn't provided,
      // so we cannot just pass in null or undefined, but we have to omit the prop entirely
      {...(customId ? { id: customId } : {})}
      className={cn(
        "group/tab-button flex-1 rounded text-neutral font-semibold",
        context.size === "small" && "p-4",
        context.size === "medium" && "p-[6px]",
        "hover:bg-neutral",
        "aria-selected:bg-elevation-raised"
      )}
      render={(localProps) => {
        if (props.href) {
          return (
            <Link href={props.href} {...localProps}>
              <div className="block text-center">{props.children}</div>
            </Link>
          )
        }

        return (
          <button type="button" {...localProps}>
            {props.children}
          </button>
        )
      }}
    />
  )
}

// Tab panel
// --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---

export function DMTabPanel(props: Ariakit.TabPanelProps) {
  return <Ariakit.TabPanel {...props} />
}
