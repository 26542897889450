//
//  SDKAssetGroupResolver.ts
//  Supernova SDK
//
//  Created by Jiri Trecak.
//
// --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
// MARK: - Imports
import { Asset } from "../../model/assets/SDKAsset"
import { AssetGroup } from "../../model/groups/SDKAssetGroup"
import { DesignComponentGroupRemoteModel } from "../../model/groups/SDKDesignComponentGroup"

// --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
// MARK: - Function Definition

export class AssetGroupResolver {
  // --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
  // MARK: - Configuration

  assets: Array<Asset>

  // --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
  // MARK: - Constructor

  constructor(assets: Array<Asset>) {
    this.assets = assets
  }

  // --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
  // MARK: - Resolution

  resolveGroupData(
    data: Array<DesignComponentGroupRemoteModel>
  ): Array<AssetGroup> {
    const hashedGroups = new Map<string, DesignComponentGroupRemoteModel>()
    const resolvedGroups = new Map<string, AssetGroup>()
    const allowedAssetIds = this.assets.map((a) => a.id)

    const assetIdsToSourceId = new Map<string, string>()
    this.assets.forEach((asset) => {
      if (asset.origin?.sourceId) {
        assetIdsToSourceId.set(asset.id, asset.origin.sourceId)
      }
    })
    const assetIdsToSourceName = new Map<string, string>()
    this.assets.forEach((asset) => {
      if (asset.origin?.fileName) {
        assetIdsToSourceName.set(asset.id, asset.origin.fileName)
      }
    })

    // Convert raw groups to resolved groups, not caring about the references just yet
    for (const rawGroup of data) {
      const group = new AssetGroup(rawGroup)

      hashedGroups.set(rawGroup.persistentId, rawGroup)
      resolvedGroups.set(rawGroup.persistentId, group)
    }

    // Build the reference tree and list of assets
    for (const rawGroup of data) {
      const filteredAssetIds = new Array<string>()
      const referencedGroup = resolvedGroups.get(rawGroup.persistentId)

      for (const id of rawGroup.childrenIds) {
        // Find if reference is group - if it is not, it is a asset or designComponent
        const childGroup = resolvedGroups.get(id)

        if (childGroup) {
          // @ts-expect-error TS(2532): Object is possibly 'undefined'.
          referencedGroup.addChild(childGroup.persistentId)
        } else if (allowedAssetIds.includes(id)) {
          // Only allow assets, and filter out designComponents
          filteredAssetIds.push(id)
        }
      }

      // @ts-expect-error TS(2532): Object is possibly 'undefined'.
      referencedGroup.assetIds = filteredAssetIds
    }

    // Retrieve resolved groups
    const groups = Array.from(resolvedGroups.values())

    this.recomputePaths(groups)
    this.recomputeParents(groups)
    const orderedGroups = this.reorderGroupsByRoots(groups)

    // Mark asset groups
    this.markAssetGroups(orderedGroups)
    this.assignSourceDataToGroups(
      groups,
      assetIdsToSourceId,
      assetIdsToSourceName
    )

    const assetGroups = orderedGroups.filter((group) => group.isAssetGroup)

    return assetGroups
  }

  // Mark groups that directly contain assets and their ancestors
  private markAssetGroups(groups: Array<AssetGroup>) {
    for (const group of groups) {
      if (group.assetIds.length > 0) {
        group.isAssetGroup = true
        if (group.parentGroupId) {
          this.markAssetAncestors(group.parentGroupId, groups)
        }
      }
    }
  }

  // Helper function to recursively mark ancestor groups
  private markAssetAncestors(
    parentGroupId: string,
    allGroups: Array<AssetGroup>
  ) {
    const parentGroup = allGroups.find((g) => g.persistentId === parentGroupId)
    if (parentGroup) {
      parentGroup.isAssetGroup = true
      if (parentGroup.parentGroupId) {
        this.markAssetAncestors(parentGroup.parentGroupId, allGroups)
      }
    }
  }

  private assignSourceDataToGroups(
    groups: Array<AssetGroup>,
    assetIdsToSourceId: Map<string, string>,
    assetIdsToSourceName: Map<string, string>
  ) {
    // Assign sources to groups containing assets
    for (const group of groups) {
      const sources = new Map<string, { id: string; name: string }>()
      for (const assetId of group.assetIds) {
        const sourceId = assetIdsToSourceId.get(assetId)
        const sourceName = assetIdsToSourceName.get(assetId)

        if (sourceId && sourceName) {
          sources.set(sourceId, { id: sourceId, name: sourceName })

          // Set first source as the default sourceId/sourceName for backwards compatibility
          if (!group.sourceId) {
            group.sourceId = sourceId
            group.sourceName = sourceName
          }
        }
      }
      // Convert map to array and assign to group
      group.sources = Array.from(sources.values())

      // spread it recursively to all ancestors
      if (group.parentGroupId && group.sourceId && group.sourceName) {
        this.propagateSourceDataToAncestors(
          group.parentGroupId,
          group.sources,
          groups
        )
      }
    }
  }

  private propagateSourceDataToAncestors(
    parentGroupId: string,
    sources: Array<{ id: string; name: string }>,
    allGroups: Array<AssetGroup>
  ) {
    const parentGroup = allGroups.find((g) => g.persistentId === parentGroupId)
    if (parentGroup) {
      // Create a map of existing sources to avoid duplicates
      const existingSources = new Map(
        parentGroup.sources?.map((s) => [s.id, s]) ?? []
      )

      // Add new sources that don't already exist
      sources.forEach((source) => {
        if (!existingSources.has(source.id)) {
          if (!parentGroup.sources) {
            parentGroup.sources = []
          }
          parentGroup.sources.push(source)

          // Set first source as the default sourceId/sourceName for backwards compatibility
          if (!parentGroup.sourceId) {
            parentGroup.sourceId = source.id
            parentGroup.sourceName = source.name
          }
        }
      })

      if (parentGroup.parentGroupId) {
        this.propagateSourceDataToAncestors(
          parentGroup.parentGroupId,
          sources,
          allGroups
        )
      }
    }
  }

  private recomputePaths(groups: Array<AssetGroup>) {
    // Find roots, and compute the segments down from the roots
    for (const group of groups) {
      if (group.isRoot) {
        this.recomputePathsFromRoot(group, [], groups)
      }
    }

    // Drop first item because we don't want the core root category to be there
    for (const group of groups) {
      group.path.shift()
    }
  }

  private recomputePathsFromRoot(
    root: AssetGroup,
    segments: Array<string>,
    groups: Array<AssetGroup>
  ) {
    // Recursively go down the tree(s) and add segments to each
    const extendedPath = segments.concat(root.name)

    for (const groupId of root.subgroupIds) {
      const group = groups.find((g) => g.persistentId === groupId)

      // @ts-expect-error TS(2532): Object is possibly 'undefined'.
      group.path = extendedPath.concat()
      // @ts-expect-error TS(2345): Argument of type 'AssetGroup | undefined' is not a... Remove this comment to see the full error message
      this.recomputePathsFromRoot(group, extendedPath, groups)
    }
  }

  private reorderGroupsByRoots(groups: Array<AssetGroup>): Array<AssetGroup> {
    let sortedGroups = new Array<AssetGroup>()
    // Find the root groups, which will be initial sorting points
    const roots = groups.filter((g) => g.isRoot)

    for (const root of roots) {
      // For each group, traverse and add proper order
      sortedGroups = sortedGroups.concat(this.traverseSortGroup(root, groups))
    }

    return sortedGroups
  }

  private traverseSortGroup(
    group: AssetGroup,
    groups: Array<AssetGroup>
  ): Array<AssetGroup> {
    let output = new Array<AssetGroup>()

    // Iterated group always first
    output.push(group)

    // Add sorted groups to the array
    const sortedGroupIds = group.subgroupIds.sort(
      (g1, g2) => group.childrenIds.indexOf(g1) - group.childrenIds.indexOf(g2)
    )

    for (const subgroupId of sortedGroupIds) {
      const subgroup = groups.find((g) => g.persistentId === subgroupId)

      // @ts-expect-error TS(2345): Argument of type 'AssetGroup | undefined' is not a... Remove this comment to see the full error message
      output = output.concat(this.traverseSortGroup(subgroup, groups))
    }

    return output
  }

  private recomputeParents(groups: Array<AssetGroup>) {
    // Find roots, and compute the references down the chain. Root groups don't have parents
    for (const group of groups) {
      if (group.isRoot) {
        this.recomputeParentsFromRoot(group, groups)
        group.parentGroupId = null
      }
    }
  }

  private recomputeParentsFromRoot(
    rootGroup: AssetGroup,
    groups: Array<AssetGroup>
  ) {
    for (const subgroupId of rootGroup.subgroupIds) {
      const subgroup = groups.find((g) => g.persistentId === subgroupId)

      // @ts-expect-error TS(2532): Object is possibly 'undefined'.
      subgroup.setParentGroupId(rootGroup.persistentId)
      // @ts-expect-error TS(2345): Argument of type 'AssetGroup | undefined' is not a... Remove this comment to see the full error message
      this.recomputeParentsFromRoot(subgroup, groups)
    }
  }

  // --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
  // MARK: - Authenticated data fetching
}
