//
//  SDKPipeline.ts
//  Supernova SDK
//
//  Created by Miro Koczka
//
// --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
// MARK: - Imports
import { DTOPipeline } from "@supernova-studio/client"

import {
  ExportBuild,
  ExportBuildModel,
  ExportBuildStatus,
} from "./exporter_builds/SDKExportBuild"

// --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
// MARK: - Definitions

export type ExporterConfigurationProperties = Record<
  string,
  boolean | number | string | Array<string> | Record<string, string>
>

export type NewPipelinePayloadDestination =
  | "Github"
  | "Gitlab"
  | "Bitbucket"
  | "Azure"
  | "WebhookUrl"
  | "S3"
  | "Documentation"

export class Pipeline {
  // --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
  // MARK: - Public properties

  id: string

  workspaceId: string

  designSystemId: string

  name: string

  isEnabled: boolean

  exporterConfigurationProperties?: ExporterConfigurationProperties

  eventType: "OnVersionReleased" | "OnHeadChanged" | "OnSourceUpdated" | "None"

  exporterId: string

  destinations: {
    webhookUrl?: string
    documentation?: {
      environment: "Live" | "Preview"
    }
    s3?: Record<any, any>
    github?: {
      url: string
      branch: string
      relativePath?: string
    }
    azure?: {
      organizationId: string
      projectId: string
      repositoryId: string
      branch: string
      relativePath?: string
      url?: string
    }
    gitlab?: {
      projectId: string
      branch: string
      relativePath?: string
      url?: string
    }
    bitbucket?: {
      workspaceSlug: string
      projectKey: string
      repoSlug: string
      branch: string
      relativePath?: string
      url?: string
    }
  }

  brandPersistentId?: string

  themePersistentIds?: string[]

  /** @deprecated Use themePersistentIds instead */
  themePersistentId?: string

  latestJobs: Array<ExportBuild>

  // --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
  // MARK: - Constructor

  constructor(data: DTOPipeline) {
    this.id = data.id
    this.workspaceId = data.workspaceId
    this.designSystemId = data.designSystemId
    this.name = data.name
    this.isEnabled = data.isEnabled
    this.exporterConfigurationProperties = data.exporterConfigurationProperties
    this.eventType = data.eventType
    this.exporterId = data.exporterId
    this.destinations = {
      webhookUrl: data.webhookUrl,
      documentation: data.destinationSnDocs,
      s3: data.destinationS3,
      github: data.destinationGithub,
      azure: data.destinationAzure,
      gitlab: data.destinationGitlab,
      bitbucket: data.destinationBitbucket,
    }
    this.brandPersistentId = data.brandPersistentId
    this.themePersistentIds = data.themePersistentIds
    this.themePersistentId = data.themePersistentId
    this.latestJobs = this.parseLatestJobs(data.latestJobs ?? [])
  }

  // return boolean if azure bitbucket gitlab and github are null
  get isBuildOnly(): boolean {
    return (
      this.destinations.azure === null &&
      this.destinations.bitbucket === null &&
      this.destinations.gitlab === null &&
      this.destinations.github === null
    )
  }

  public get gitProviderType():
    | "github"
    | "bitbucket"
    | "gitlab"
    | "azure"
    | null {
    if (this.destinations.github) {
      return "github"
    }

    if (this.destinations.bitbucket) {
      return "bitbucket"
    }

    if (this.destinations.gitlab) {
      return "gitlab"
    }

    if (this.destinations.azure) {
      return "azure"
    }

    return null
  }

  public get toRemoteModel(): DTOPipeline {
    return {
      id: this.id,
      workspaceId: this.workspaceId,
      designSystemId: this.designSystemId,
      name: this.name,
      isEnabled: this.isEnabled,
      eventType: this.eventType,
      exporterConfigurationProperties: this.exporterConfigurationProperties,
      exporterId: this.exporterId,
      webhookUrl: this.destinations.webhookUrl,
      destinationSnDocs: this.destinations.documentation,
      destinationS3: this.destinations.s3,
      destinationGithub: this.destinations.github,
      destinationAzure: this.destinations.azure,
      destinationGitlab: this.destinations.gitlab,
      destinationBitbucket: this.destinations.bitbucket,
      brandPersistentId: this.brandPersistentId,
      themePersistentIds: this.themePersistentIds,
      themePersistentId: this.themePersistentId,
      latestJobs: [],
    }
  }

  public get isRunning(): boolean {
    return this.latestJobs.some(
      (job) => job.status === ExportBuildStatus.InProgress
    )
  }

  private parseLatestJobs(latestJobs: DTOPipeline["latestJobs"]) {
    return latestJobs.map(
      (job) => new ExportBuild(job as unknown as ExportBuildModel)
    )
  }
}
