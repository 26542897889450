import { DTOExporterPropertyDefinitionEnum } from "@supernova-studio/client"

import {
  ExporterPropertyBase,
  ExporterPropertyType,
} from "./SDKExporterPropertyBase"

type ExporterPropertyDefinitionEnumOption = {
  key: string
  label: string
  description: string
}

export class ExporterPropertyDefinitionEnum extends ExporterPropertyBase {
  // --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
  // MARK: - Public properties

  type: ExporterPropertyType = "Enum"

  options: Array<ExporterPropertyDefinitionEnumOption>

  defaultValue: string

  // --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
  // MARK: - Constructor

  constructor(model: DTOExporterPropertyDefinitionEnum) {
    super(model)

    this.options = Object.entries(model.options).map(([key, value]) => ({
      key,
      label: value.label,
      description: value.description,
    }))
    this.defaultValue = model.default
  }
}
