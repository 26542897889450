import React from "react"

import { cn } from "@supernovaio/dm/src/utils/cn"

import {
  TokenDisplayValueProps,
  TokenValueSegment,
} from "../DMTokenPreview.types"

import { ValueSegment } from "./partials/ValueSegment"

export function DMTokenDisplayValue(
  props: TokenDisplayValueProps & { wrap?: boolean; wrapSegments?: boolean }
) {
  const {
    size = "medium",
    variant = "neutral",
    segments,
    noOfSegments,
    isTooltipEnabled = true,
    wrap = false,
    wrapSegments = false,
    forceRawValue = false,
  } = props

  const rootClassName = cn(
    "inline-flex gap-4 text-body text-neutral overflow-auto no-scrollbar",
    {
      "text-neutral-faded": variant === "neutral-faded",
      "text-body-small": size === "small",
      "whitespace-nowrap": !wrap,
      "flex-wrap": wrapSegments,
    }
  )

  const valueSegments = Object.values(segments)
    .slice(0, noOfSegments || Infinity)
    .map((segment: TokenValueSegment | "separator", index) => {
      return (
        <ValueSegment
          key={`segment-${index}`}
          forceRawValue={forceRawValue}
          isTooltipEnabled={isTooltipEnabled}
          segment={segment}
        />
      )
    })

  return <div className={rootClassName}>{valueSegments}</div>
}
