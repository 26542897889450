//
//  SDKAssetGroup.ts
//  Supernova SDK
//
//  Created by Jiri Trecak.
//
// --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
// MARK: - Imports
import { DesignComponentGroupRemoteModel } from "./SDKDesignComponentGroup"

// --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
// MARK: - Definitions

// --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
// MARK: -  Object Definition

export class AssetGroup {
  // --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
  // MARK: - Public properties

  id: string

  persistentId: string

  brandId: string

  name: string

  description: string

  path: Array<string>

  subgroupIds: Array<string>

  isRoot: boolean

  childrenIds: Array<string>

  assetIds: Array<string>

  parentGroupId: string | null

  createdAt: Date | null

  updatedAt: Date | null

  isAssetGroup: boolean

  // @deprecated: use sources instead
  sourceId: string | null

  // @deprecated: use sources instead
  sourceName: string | null

  sources: Array<{ id: string; name: string }>

  // --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
  // MARK: - Constructor

  constructor(model: DesignComponentGroupRemoteModel) {
    this.id = model.id
    this.persistentId = model.persistentId
    this.brandId = model.brandId
    this.name = model.meta.name
    this.description = model.meta.description
    this.isRoot = model.isRoot
    this.childrenIds = model.childrenIds
    this.path = new Array<string>()
    this.assetIds = new Array<string>()
    this.subgroupIds = new Array<string>()
    this.parentGroupId = null
    this.createdAt = model.createdAt ? new Date(model.createdAt) : null
    this.updatedAt = model.updatedAt ? new Date(model.updatedAt) : null
    this.isAssetGroup = false
    this.sourceId = null
    this.sourceName = null
    this.sources = []
  }

  // --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
  // MARK: - Convenience

  addChild(groupId: string) {
    this.subgroupIds.push(groupId)
  }

  addChildren(groupsIds: Array<string>) {
    this.subgroupIds = this.subgroupIds.concat(groupsIds)
  }

  setPath(segments: Array<string>) {
    this.path = segments
  }

  setParentGroupId(parentGroupId: string | null) {
    this.parentGroupId = parentGroupId ?? null
  }
}
