import React, { useRef, useState } from "react"

import { cn } from "@supernovaio/dm/src/utils/cn"

import { DMButtonWhite } from "../DMButtons"

type DMFileDropAreaProps = {
  className?: string
  buttonText?: string
  actionText?: string
  descriptionText?: string
  accept?: HTMLInputElement["accept"]
  onFileChange: (file: File) => void
}

const isFileAllowed = (
  file?: File | null,
  accept?: HTMLInputElement["accept"]
): file is File => {
  if (!file) {
    return false
  }

  if (!accept) {
    return true
  }

  const allowedTypes = accept.split(",").map((type) => type.trim())

  return allowedTypes.some((type) => {
    if (type.startsWith(".")) {
      return file.name.toLowerCase().endsWith(type.toLowerCase())
    }

    const category = type.split("/")[0]
    if (type.endsWith("/*") && category) {
      return file.type.startsWith(category)
    }

    return file.type === type
  })
}

export function DMFileDropArea({
  className,
  buttonText = "Browse",
  actionText,
  descriptionText,
  accept,
  onFileChange,
}: DMFileDropAreaProps) {
  const fileInputRef = useRef<HTMLInputElement>(null)
  const [isHovering, setIsHovering] = useState(false)

  const handleDragEnd = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault()
    setIsHovering(false)
  }

  return (
    <div
      className={cn(
        "flex flex-col gap-16 p-16 items-center justify-center rounded-medium border border-dashed border-neutral-faded bg-neutral-faded h-full w-full",
        className,
        isHovering && "border-primary-faded"
      )}
      onDragOver={(e) => {
        e.preventDefault()
        setIsHovering(true)
      }}
      onDragLeave={handleDragEnd}
      onDragEnd={handleDragEnd}
      onDrop={(e) => {
        e.preventDefault()
        e.stopPropagation()

        handleDragEnd(e)

        const droppedFile = e.dataTransfer.files[0]
        if (!isFileAllowed(droppedFile, accept)) {
          return
        }

        onFileChange(droppedFile)
      }}
    >
      <input
        className="hidden"
        ref={fileInputRef}
        type="file"
        accept={accept}
        onChange={(e) => {
          const file = e.target.files?.[0]

          if (file) {
            onFileChange(file)
          }
        }}
      />
      <p className="text-body font-semibold">{actionText}</p>
      <DMButtonWhite onClick={() => fileInputRef.current?.click()}>
        {buttonText}
      </DMButtonWhite>
      <p className="text-body-small text-neutral-faded">{descriptionText}</p>
    </div>
  )
}
