// SDKExportDestinations.ts
// Supernova SDK
//
// Created by Emir Bostanci.
// Copyright © 2023 Supernova. All rights reserved.
//
// MARK: - Imports
// TODO:fix-sdk-eslint
// eslint-disable-next-line max-classes-per-file
import { ExportBuildResult } from "./SDKExportBuildResults"
import { ExportDestinations } from "./SDKExportDestinations"

// MARK: - Export build created by
export type ExportBuildCreatedByModel = {
  userId: string
  userName: string | null
}

export class ExportBuildCreatedBy {
  userId: string

  userName: string | null

  constructor(data: ExportBuildCreatedByModel) {
    this.userId = data.userId
    this.userName = data.userName
  }
}

/**
 * The enum representing possible statuses of an export build.
 * @enum {string}
 */
export enum ExportBuildStatus {
  InProgress = "InProgress",
  Success = "Success",
  Failed = "Failed",
  Timeout = "Timeout",
}

export type ExportBuildModel = {
  index: number | null
  id: string
  createdAt: Date
  finishedAt: Date
  designSystem: {
    id: string
    meta: {
      name: string
      description: string
    }
  }
  designSystemVersion: {
    id: string
    meta: {
      name: string
      description: string
    }
    version: string
    isReadonly: boolean
  }
  result: ExportBuildResult
  status: ExportBuildStatus
  estimatedExecutionTime: number | null
  createdBy: ExportBuildCreatedBy | null
  destinations: ExportDestinations
  scheduleId: string | null
  exporterId: string
  brandPersistentId: string | null
  themePersistentId: string | null
}

/** Build run entry */
export class ExportBuild {
  /** The index of the export build, or null if not provided */
  index: number | null

  /** The unique identifier for the export build */
  id: string

  /** The date and time when the export build was created */
  createdAt: Date

  /** The date and time when the export build was finished, or null if not finished yet */
  finishedAt: Date | null

  /** The design system associated with the export build */
  designSystem: {
    id: string
    meta: {
      name: string
      description?: string
    }
  }

  /** The version of the design system used in the export build */
  designSystemVersion: {
    id: string
    meta: {
      name: string
      description?: string
    }
    version: string
    isReadonly: boolean
  }

  /** The result of the export build, or null if not available */
  result: ExportBuildResult | null

  /** The status of the export build */
  status: ExportBuildStatus

  /** The estimated execution time for the export build, or null if not available */
  estimatedExecutionTime: number | null

  /** The user who created the export build, or null if not available */
  createdBy: ExportBuildCreatedBy | null

  /** The destinations associated with the export build */
  destinations: ExportDestinations

  /** The identifier of the hook associated with the export build, or null if not available */
  scheduleId: string | null

  /** The identifier of the exporter associated with the export build */
  exporterId: string

  /** The identifier of the brand used for this build */
  brandId: string | null

  /** The identifier of the theme used for this build */
  themeId: string | null

  constructor(data: ExportBuildModel) {
    this.index = data.index ?? null
    this.id = data.id
    this.createdAt = new Date(data.createdAt)
    this.finishedAt = data.finishedAt ? new Date(data.finishedAt) : null
    this.designSystem = data.designSystem
    this.designSystemVersion = data.designSystemVersion
    this.result = data.result ?? null
    this.status = data.status
    this.estimatedExecutionTime = data.estimatedExecutionTime ?? null
    this.createdBy = data.createdBy ?? null
    this.destinations = data.destinations
    this.scheduleId = data.scheduleId ?? null
    this.exporterId = data.exporterId
    this.brandId = data.brandPersistentId ?? null
    this.themeId = data.themePersistentId ?? null
  }

  /**
   * Gets the URL for downloading the code generated by the export build.
   * @returns {string|null} - The URL for downloading the code, or null if not available.
   */
  get downloadCodeUrl(): string | null {
    return this.result?.s3?.url ?? null
  }

  /**
   * Gets the URL for the pull request associated with the export build.
   * @returns {string|null} - The URL for the pull request, or null if not available.
   */
  get pullRequestUrl(): string | null {
    // @ts-expect-error TS(2322): Type 'string | undefined' is not assignable to typ... Remove this comment to see the full error message
    return (
      this.result?.azure?.pullRequestUrl ??
      this.result?.bitbucket?.pullRequestUrl ??
      this.result?.github?.pullRequestUrl ??
      this.result?.gitlab?.pullRequestUrl
    )
  }

  /**
   * Returns the URL for published documentation associated with the export build.
   * @returns {string|null} - The URL to the documentation, or null if not available.
   */
  get documentationUrl(): string | null {
    return this.result?.documentation?.url || null
  }
}
