import React, { ReactNode } from "react"

import { IconCheckCircle, IconCircleDashed } from "@supernovaio/icons"

import { cn } from "../../utils/cn"
import { DMIcon } from "../DMIcon"

type Props = {
  children: ReactNode
}

export function DMWizardNavigation(props: Props) {
  // We need to interleave the children with dashed lines, so we need to have an array
  const children = React.Children.toArray(props.children)

  return (
    <div className="flex flex-row items-center">
      {children.flatMap((item, index) => {
        return [
          item,
          index < children.length - 1 ? (
            <hr
              key={index}
              // The selected item has a 2px border, while the regular one only has 1px. We offset this
              // by including 1px padding to the regular one, so the layout doesn't jump, but that also
              // means there would be a gap between this dashed line and the item, so we need -1 margin
              className="border-neutral border-dashed mx-[-1px] w-[18px] h-1"
            />
          ) : null,
        ]
      })}
    </div>
  )
}

type ItemProps = {
  fixedWidth?: number
  title: string
  description?: string
  isDisabled?: boolean
  isCompleted: boolean
  isSelected?: boolean
  onSelected: () => void
}

export function DMWizardNavigationItem(props: ItemProps) {
  return (
    // The z-index is necessary, so that the dashed line is behind the selection border.
    // The selected item has a 2px border, while the regular one only has 1px. We offset this
    // by including 1px padding to the regular one, so the layout doesn't jump
    <div className={cn("z-2", !props.isSelected && "p-1")}>
      <button
        type="button"
        disabled={props.isDisabled}
        className={cn(
          "flex flex-row px-12 py-8 text-left items-center gap-16 justify-between",
          "rounded border border-neutral outline-none ring-offset-2 focus-visible:ring-2",
          props.isSelected && "border-2 border-primary"
        )}
        style={{ width: props.fixedWidth }}
        onClick={props.onSelected}
      >
        <div className="flex flex-col">
          <p
            className={cn(
              "text-body text-neutral font-bold",
              props.isDisabled && "text-disabled"
            )}
          >
            {props.title}
          </p>
          {props.description && (
            <p
              className={cn(
                "text-body-small text-neutral-faded font-semibold",
                props.isDisabled && "text-disabled"
              )}
            >
              {props.description}
            </p>
          )}
        </div>

        {props.isCompleted ? (
          <DMIcon
            color={props.isDisabled ? "disabled" : "neutral"}
            svg={<IconCheckCircle />}
          />
        ) : (
          <DMIcon
            color={props.isDisabled ? "disabled" : "neutral-placeholder"}
            svg={<IconCircleDashed />}
          />
        )}
      </button>
    </div>
  )
}
