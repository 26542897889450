//
//  SDKTokenType.ts
//  Supernova SDK
//
//  Created by Jiri Trecak.
//

// --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
// MARK: - Token type enum

export enum TokenType {
  color = "Color",
  typography = "Typography",
  shadow = "Shadow",
  border = "Border",
  gradient = "Gradient",
  blur = "Blur",

  radius = "BorderRadius",
  borderWidth = "BorderWidth",
  duration = "Duration",
  fontSize = "FontSize",
  dimension = "Dimension",
  letterSpacing = "LetterSpacing",
  lineHeight = "LineHeight",
  opacity = "Opacity",
  paragraphSpacing = "ParagraphSpacing",
  size = "Size",
  space = "Space",
  zIndex = "ZIndex",

  textDecoration = "TextDecoration",
  textCase = "TextCase",
  visibility = "Visibility",

  fontFamily = "FontFamily",
  fontWeight = "FontWeight",
  string = "String",
  productCopy = "ProductCopy",
}

export const STRING_TOKEN_TYPES = [
  TokenType.string,
  TokenType.productCopy,
  TokenType.fontFamily,
  TokenType.fontWeight,
] as const
export type StringTokenType = (typeof STRING_TOKEN_TYPES)[number]

export const OPTION_TOKEN_TYPES = [
  TokenType.textCase,
  TokenType.textDecoration,
  TokenType.visibility,
] as const
export type OptionTokenType = (typeof OPTION_TOKEN_TYPES)[number]

export const DIMENSION_TOKEN_TYPES = [
  TokenType.dimension,
  TokenType.size,
  TokenType.space,
  TokenType.opacity,
  TokenType.fontSize,
  TokenType.lineHeight,
  TokenType.letterSpacing,
  TokenType.paragraphSpacing,
  TokenType.borderWidth,
  TokenType.radius,
  TokenType.duration,
  TokenType.zIndex,
] as const

export type DimensionTokenType = (typeof DIMENSION_TOKEN_TYPES)[number]
export const RAW_DIMENSION_TOKEN_TYPES = [
  TokenType.opacity,
  TokenType.zIndex,
] as const

export const MS_DIMENSION_TOKEN_TYPES = [TokenType.duration] as const

export const ALL_TOKEN_TYPES = [
  ...DIMENSION_TOKEN_TYPES,
  ...STRING_TOKEN_TYPES,
  ...OPTION_TOKEN_TYPES,
  TokenType.color,
  TokenType.gradient,
  TokenType.border,
  TokenType.radius,
  TokenType.shadow,
  TokenType.typography,
  TokenType.blur,
] as const

export const PURE_TOKEN_TYPES = [
  ...DIMENSION_TOKEN_TYPES,
  ...STRING_TOKEN_TYPES,
  ...OPTION_TOKEN_TYPES,
] as const

export type PureTokenType = (typeof PURE_TOKEN_TYPES)[number]
export type NonPureTokenType = Exclude<TokenType, PureTokenType>

export const tokenTypeIsPure = (
  tokenType: TokenType
): tokenType is PureTokenType => {
  return PURE_TOKEN_TYPES.includes(tokenType as PureTokenType)
}

export const tokenTypeIsNonPure = (
  tokenType: TokenType
): tokenType is NonPureTokenType => {
  return !tokenTypeIsPure(tokenType)
}

export const REPLACABLE_TOKEN_TYPES = [
  TokenType.color,
  ...DIMENSION_TOKEN_TYPES,
  ...STRING_TOKEN_TYPES,
  ...OPTION_TOKEN_TYPES, // FIXME: Should they be here
] as const
export type ReplacableTokenType = (typeof REPLACABLE_TOKEN_TYPES)[number]

export const REFERENCABLE_TOKEN_TYPES = [
  TokenType.color,
  ...DIMENSION_TOKEN_TYPES,
  TokenType.fontFamily,
  TokenType.fontWeight,
  TokenType.textCase,
  TokenType.textDecoration,
] as const
export type ReferencableTokenType = (typeof REFERENCABLE_TOKEN_TYPES)[number]

export const tokenTypeIsReferencable = (
  tokenType: TokenType
): tokenType is ReferencableTokenType => {
  return REFERENCABLE_TOKEN_TYPES.includes(tokenType as ReferencableTokenType)
}

export const DT_TOKEN_TYPES = [
  TokenType.color,
  TokenType.shadow,
  TokenType.gradient,
  TokenType.typography,
  TokenType.border,
  ...DIMENSION_TOKEN_TYPES,
  TokenType.fontFamily,
  TokenType.fontWeight,
  TokenType.string,
  ...OPTION_TOKEN_TYPES,
] as const
export type DTTokenType = (typeof DT_TOKEN_TYPES)[number]

export type DTOriginalTokenType =
  | "color"
  | "borderRadius"
  | "boxShadow"
  | "gradient"
  | "typography"
  | "border"
  | "borderWidth"
  | "sizing"
  | "opacity"
  | "spacing"
  | "fontSizes"
  | "paragraphSpacing"
  | "letterSpacing"
  | "lineHeights"
  | "other"
  | "text"
  | "dimension"
  | "number"
  | "fontFamilies"
  | "fontWeights"
  | "textCase"
  | "textDecoration"
  | "duration"
