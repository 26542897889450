import { DTOExporterPropertyDefinitionString } from "@supernova-studio/client"

import {
  ExporterPropertyBase,
  ExporterPropertyType,
} from "./SDKExporterPropertyBase"

export class ExporterPropertyDefinitionString extends ExporterPropertyBase {
  // --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
  // MARK: - Public properties

  type: ExporterPropertyType = "String"

  defaultValue: string

  // --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
  // MARK: - Constructor

  constructor(model: DTOExporterPropertyDefinitionString) {
    super(model)

    this.defaultValue = model.default
  }
}
