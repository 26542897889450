import { useCallback, useId, useMemo } from "react"

import { getClientSdk } from "@supernovaio/cloud/utils/data/getClientSdk"
import { Brand } from "@supernovaio/sdk"

import { useQueryClient } from "@tanstack/react-query"

import { useSafeQuery } from "../useSafeQuery"

import { createVersionQueryKey } from "./useVersion"

type QueryIds = {
  dsId: string
  versionId: string
}

export const createBrandsQueryKey = (ids: QueryIds) => [
  ...createVersionQueryKey(ids),
  "brands",
]

export const useInvalidateBrands = () => {
  const queryClient = useQueryClient()

  return useCallback(
    (ids: QueryIds) =>
      queryClient.invalidateQueries({
        queryKey: createBrandsQueryKey(ids),
      }),
    [queryClient]
  )
}

export const useBrands = (dsId?: string, versionId?: string) => {
  const fallbackDsId = useId()
  const fallbackVersionId = useId()

  return useSafeQuery({
    queryKey: createBrandsQueryKey({
      dsId: dsId || fallbackDsId,
      versionId: versionId || fallbackVersionId,
    }),
    queryFn: async () => {
      if (!dsId || !versionId) {
        return []
      }

      const sdk = await getClientSdk()

      return sdk.brands.getBrands({
        designSystemId: dsId,
        versionId,
      })
    },
  })
}

export const useBrandsById = (dsId?: string, versionId?: string) => {
  const { data: brands, isLoading: areBrandsLoading } = useBrands(
    dsId,
    versionId
  )

  return {
    brandsById: useMemo(() => {
      return brands?.reduce<Map<string, Brand>>((aggregatorMap, brand) => {
        aggregatorMap.set(brand.id, brand)
        return aggregatorMap
      }, new Map())
    }, [brands]),
    areBrandsLoading,
  }
}
