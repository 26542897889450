"use client"

import React from "react"
import * as ResizablePrimitive from "react-resizable-panels"

import { cn } from "../../utils/cn"

function DMResizablePanelGroup({
  className,
  ...props
}: React.ComponentProps<typeof ResizablePrimitive.PanelGroup>) {
  return (
    <ResizablePrimitive.PanelGroup
      className={cn(
        "flex h-full w-full data-[panel-group-direction=vertical]:flex-col",
        className
      )}
      {...props}
    />
  )
}

const DMResizablePanel = ResizablePrimitive.Panel

function DMResizableHandle(
  props: Pick<
    React.ComponentProps<typeof ResizablePrimitive.PanelResizeHandle>,
    "onDragging"
  >
) {
  return (
    <ResizablePrimitive.PanelResizeHandle
      className={cn(
        "relative flex items-center justify-center",
        "after:absolute after:inset-y-0 after:left-1/2 after:w-[1px] after:-translate-x-1/2",
        "focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring focus-visible:ring-offset-1",
        "data-[panel-group-direction=vertical]:w-full data-[panel-group-direction=vertical]:after:left-0 data-[panel-group-direction=vertical]:after:h-[1px] data-[panel-group-direction=vertical]:after:w-full data-[panel-group-direction=vertical]:after:-translate-y-1/2 data-[panel-group-direction=vertical]:after:translate-x-0 [&[data-panel-group-direction=vertical]>div]:rotate-90"
      )}
      {...props}
    />
  )
}

export { DMResizablePanelGroup, DMResizablePanel, DMResizableHandle }
